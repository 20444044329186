/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
// import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
// } from '../../../_metronic/partials/widgets'
import {AccountStat} from './components/AccountStat'
import {StudentStat} from './components/StudentStat'
import {useAuth} from '../../modules/auth'
import Announcements from './components/Announcements'
import InvoiceStat from './components/InvoiceStat'
import FeeTypeStat from './components/FeeTypeStat'
import {Cal} from './components/Calendar'

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  return (
    <>
      {/* begin::Row */}
      {currentUser && currentUser?.role_id <= 3 && (
          <div className='d-flex flex-column flex-lg-row gap-8 gap-lg-4'>
            <StudentStat className='mb-5 flex-grow-1' />
            <AccountStat className='mb-5 flex-grow-1' />
            {/* <AccountStat className='mb-5 flex-grow-1' /> */}
          </div>
        )}

      <div className='d-flex flex-column flex-lg-row justify-content-between w-100 gap-4'>
      <Cal />
        <Announcements />
      </div>

      <div className='d-flex flex-column mt-8'>
       
        <div className='d-flex flex-column flex-lg-row gap-8 gap-lg-4'>
          <InvoiceStat />
          <FeeTypeStat />
        </div>
      </div>

      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
