import React from 'react'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'

const Reminder = () => {
  return (
    <div className='card w-lg-350px w-100'>
      <b className='w-100 p-4 border-bottom border-1 border-gray fs-8'>Reminder</b>

      <div className='d-flex justify-content-center align-items-center flex-column py-8'>
        <NotificationsOffIcon className='text-secondary' sx={{fontSize: 78}} />

        <h5>No Reminder</h5>
      </div>
    </div>
  )
}

export default Reminder
