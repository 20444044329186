import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Routine, RoutinesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_V2_URL
const ROUTINE_URL = `${API_URL}/routine`

const getRoutines = (query: string): Promise<RoutinesQueryResponse> => {
  return axios.get(`${ROUTINE_URL}?${query}&include=Subject&include=Class&include=Section&include=Employee&include=ExamTerm`)
}

const getRoutineById = (id: ID): Promise<Routine | undefined> => {
  return axios.get(`${ROUTINE_URL}/${id}?includes=Subject,Class,Section`)
}

const createRoutine = (routine: Routine): Promise<Routine | undefined> => {
  return axios.post(ROUTINE_URL, routine)
}

const updateRoutine = (routine: Routine): Promise<Routine | undefined> => {
  return axios.patch(`${ROUTINE_URL}/${routine.id}`, routine)
}

const deleteRoutine = (routineId: ID): Promise<void> => {
  return axios.delete(`${ROUTINE_URL}/${routineId}`)
}

const deleteSelectedRoutines = async (routineIds: Array<ID>): Promise<void> => {
  const requests = routineIds.map((id) => axios.delete(`${ROUTINE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getRoutines,
  deleteRoutine,
  deleteSelectedRoutines,
  getRoutineById,
  createRoutine,
  updateRoutine,
}
