import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { useQuery } from '@tanstack/react-query';
import { QUERIES } from '../../../../_metronic/helpers';
import { getExamSchedules } from '../../../modules/apps/exam-schedule/exam-schedules-list/core/_requests';
import { getRoutines } from '../../../modules/apps/routine/routines-list/core/_requests';
import { ExamSchedulesQueryResponse, ExamSchedule } from '../../../modules/apps/exam-schedule/exam-schedules-list/core/_models';
import { RoutinesQueryResponse, Routine } from '../../../modules/apps/routine/routines-list/core/_models';
import { EventClickArg, EventContentArg } from '@fullcalendar/core';

export const Cal = () => {
  // Fetch exam schedules
  const { data: examSchedules } = useQuery<ExamSchedulesQueryResponse>(
    [`${QUERIES.EXAM_SCHEDULES_LIST}`],
    () => getExamSchedules('filter={"status":true}'),
    { cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  // Fetch routines
  const { data: routines } = useQuery<RoutinesQueryResponse>(
    [`${QUERIES.ROUTINES_LIST}`],
    () => getRoutines('filter={"status":true}'),
    { cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  // Helper function to get the date for a given day (for routines)
  const getDateForDay = (day: string): string => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    const currentDayIndex = today.getDay();
    const targetDayIndex = daysOfWeek.indexOf(day);
    const difference = targetDayIndex - currentDayIndex;
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + difference);
    return targetDate.toISOString().split('T')[0];
  };

  // Transform exam schedules into events
  const transformExamSchedules = (examSchedules: ExamSchedule[] | undefined) => {
    if (!examSchedules) return [];
    return examSchedules.map((examSchedule: ExamSchedule) => {
      const examDate = examSchedule.exam_date ? new Date(examSchedule.exam_date) : new Date();
      const formattedDate = examDate.toISOString().split('T')[0];

      return {
        title: `Exam: ${examSchedule.subject?.name || 'No Subject'}`,
        extendedProps: {
          type: 'Exam',
          roomNumber: examSchedule.room_no || 'No Room',
          className: examSchedule.class?.name || 'No Class',
        },
        start: `${formattedDate}T${examSchedule.start_time || ''}`,
        end: `${formattedDate}T${examSchedule.end_time || ''}`,
        backgroundColor: 'red',
        borderColor: 'red',
        examSchedule,
      };
    });
  };

  // Transform routines into events
  const transformRoutines = (routines: Routine[] | undefined) => {
    if (!routines) return [];
    return routines.map((routine: Routine) => {
      const date = getDateForDay(routine.day || '');

      return {
        title: `Routine: ${routine.subject?.name || 'No Subject'}`,
        extendedProps: {
          type: 'Routine',
          roomNumber: routine.room_no || 'No Room',
          className: routine.class?.name || 'No Class',
        },
        start: `${date}T${routine.start_time || ''}`,
        end: `${date}T${routine.end_time || ''}`,
        backgroundColor: 'blue',
        borderColor: 'blue',
        routine,
      };
    });
  };

  // Combine both datasets
  const events = [
    ...transformExamSchedules(examSchedules?.entities),
    ...transformRoutines(routines?.entities),
  ];

  // Custom event renderer
  const renderEventContent = (eventInfo: EventContentArg) => {
    const { title, extendedProps } = eventInfo.event;
    const startTime = eventInfo.event.start?.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return (
      <div className='cursor-pointer'>
        <div>{`• ${startTime} - ${title}`}</div>
        <div>{`${extendedProps.className} - Room ${extendedProps.roomNumber}`}</div>
      </div>
    );
  };


  return (
    <div className='card w-100'>
      <div className='card-body'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          events={events}
          eventContent={renderEventContent}
        />
      </div>
    </div>
  );
};
