import React from 'react'
import Reminder from './Reminder'

const Announcements = () => {
  return (
    <div className='d-flex flex-column gap-5'>
    <div className='card w-lg-350px w-100'>
      <b className='w-100 p-4 border-bottom border-1 border-gray fs-7'>Announcement</b>

      <p className='w-100 p-4 border-bottom border-1 border-gray fs-8'>
      <b className='pb-1 d-block'>School Closure</b>
      "The school will be closed on Friday, 15th September, for essential maintenance work. Regular classes will resume on Monday, 18th September. We appreciate your cooperation."

        <b className=' d-block fs-9 mt-2'>now</b>
      </p>

      <p className='w-100 p-4 border-bottom border-1 border-gray fs-8'>
      <b className='pb-1 d-block'>School Closure</b>
      "The school will be closed on Friday, 15th September, for essential maintenance work. Regular classes will resume on Monday, 18th September. We appreciate your cooperation."
        <b className=' d-block fs-9 mt-2'>20 minutes ago</b>
      </p>

      <p className='w-100 p-4 border-bottom border-1 border-gray fs-8'>
      <b className='pb-1 d-block'>School Closure</b>
      "The school will be closed on Friday, 15th September, for essential maintenance work. Regular classes will resume on Monday, 18th September. We appreciate your cooperation."
        <b className=' d-block fs-9 mt-2'>1 hour ago</b>
      </p>
    </div>
   <Reminder/>
  </div>
  )
}

export default Announcements