import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {QUERIES} from '../../../../_metronic/helpers'
import {useQuery} from '@tanstack/react-query'
import {getInvoices} from '../../../modules/apps/invoice/invoices-list/core/_requests'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

// Use the types from the imported _models file
import type {InvoicesQueryResponse} from '../../../modules/apps/invoice/invoices-list/core/_models'

const InvoiceStat = () => {
  const {data: invoiceData} = useQuery<InvoicesQueryResponse>(
    [`${QUERIES.INVOICES_LIST}-sort_field=name&sort_order=asc&filter={"status":true}`],
    // () => getInvoices(`filter={"status":true}`),
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, invoiceData])

  const refreshChart = () => {
    if (!chartRef.current) return

    const chart = new ApexCharts(chartRef.current, getChartOptions(invoiceData))
    chart.render()
    return chart
  }

  return (
    <div className='card p-4 w-100'>
      <h5 className='fw-bold fs-7 mb-3'>Invoice Report</h5>
      <div ref={chartRef} id='invoice_chart' />
    </div>
  )
}

export default InvoiceStat

function getChartOptions(invoiceData?: InvoicesQueryResponse): ApexOptions {
  // Ensure entities is always an array
  const invoices = invoiceData?.entities ?? []

  // Group invoices by type and count occurrences by date
  const invoiceTypes: Record<string, Record<string, number>> = {}

  invoices.forEach(({invoice_date, invoice_type}) => {
    if (invoice_type) {
      if (!invoiceTypes[invoice_type]) {
        invoiceTypes[invoice_type] = {}
      }
      invoiceTypes[invoice_type][invoice_date] = (invoiceTypes[invoice_type][invoice_date] || 0) + 1
    }
  })

  // Extract all unique dates and sort them
  const allDates = Array.from(new Set(invoices.map((invoice) => invoice.invoice_date))).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  )

  // Convert invoice type data into ApexCharts series format
  const series = Object.keys(invoiceTypes).map((type, index) => ({
    name: type,
    data: allDates.map((date) => invoiceTypes[type][date] || 0),
  }))

  return {
    series,
    chart: {type: 'line', height: 250, fontFamily: 'inherit'},
    xaxis: {categories: allDates, title: {text: 'Invoice Date'}},
    yaxis: {title: {text: 'Count'}},
    stroke: {curve: 'smooth', width: 3},
    markers: {size: 5, hover: {size: 7}},
    colors: ['#007bff', '#28a745', '#ff5733', '#f4c20d'], // Different colors for each invoice type
    tooltip: {theme: 'dark'},
  }
}
