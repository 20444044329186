import axios from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { FeeType, FeeTypesQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_V2_URL
const FEE_TYPE_URL = `${API_URL}/fee-type`

const getFeeTypes = (query: string): Promise<FeeTypesQueryResponse> => {
  return axios.get(`${FEE_TYPE_URL}?${query}`)
}

const getFeeTypeById = (id: ID): Promise<FeeType | undefined> => {
  return axios.get(`${FEE_TYPE_URL}/${id}`)
}

const createFeeType = (feeType: FeeType): Promise<FeeType | undefined> => {
  return axios.post(FEE_TYPE_URL, feeType)
}

const updateFeeType = (feeType: FeeType): Promise<FeeType | undefined> => {
  return axios.patch(`${FEE_TYPE_URL}/${feeType.id}`, feeType)
}

const deleteFeeType = (feeTypeId: ID): Promise<void> => {
  return axios.delete(`${FEE_TYPE_URL}/${feeTypeId}`)
}
const deleteFeeTypeClass = (id: ID): Promise<void> => {
  return axios.delete(`${FEE_TYPE_URL}/class/${id}`)
}

const deleteSelectedFeeTypes = async (feeTypeIds: Array<ID>): Promise<void> => {
  const requests = feeTypeIds.map((id) => axios.delete(`${FEE_TYPE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getFeeTypes,
  deleteFeeType,
  deleteSelectedFeeTypes,
  getFeeTypeById,
  createFeeType,
  updateFeeType,
  deleteFeeTypeClass
}
