import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {ExamSchedule, ExamSchedulesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EXAM_SCHEDULE_URL = `${API_URL}/schedule`

const getExamSchedules = (query: string): Promise<ExamSchedulesQueryResponse> => {
  return axios.get(`${EXAM_SCHEDULE_URL}?${query}`)
}

const getExamScheduleById = (id: ID): Promise<ExamSchedule | undefined> => {
  return axios.get(`${EXAM_SCHEDULE_URL}/${id}`)
}

const createExamSchedule = (examSchedule: ExamSchedule): Promise<ExamSchedule | undefined> => {
  return axios.post(EXAM_SCHEDULE_URL, examSchedule)
}

const updateExamSchedule = (examSchedule: ExamSchedule): Promise<ExamSchedule | undefined> => {
  return axios.patch(`${EXAM_SCHEDULE_URL}`, examSchedule)
}

const deleteExamSchedule = (examScheduleId: ID): Promise<void> => {
  return axios.delete(`${EXAM_SCHEDULE_URL}/${examScheduleId}`)
}

const deleteSelectedExamSchedules = async (examScheduleIds: Array<ID>): Promise<void> => {
  const requests = examScheduleIds.map((id) => axios.delete(`${EXAM_SCHEDULE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getExamSchedules,
  deleteExamSchedule,
  deleteSelectedExamSchedules,
  getExamScheduleById,
  createExamSchedule,
  updateExamSchedule,
}
