import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { QUERIES } from "../../../../_metronic/helpers";
import { useQuery } from "@tanstack/react-query";
import { getFeeTypes } from "../../../modules/apps/fee-type/fee-types-list/core/_requests";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { FeeType as ImportedFeeType } from "../../../modules/apps/fee-type/fee-types-list/core/_models"; // Import the correct FeeType

interface FeeTypesQueryResponse {
  entities?: ImportedFeeType[];
}

const FeeTypeStat = () => {
  const { data: feeTypeData } = useQuery(
    [`${QUERIES.FEE_TYPES_LIST}-sort_field=name&sort_order=asc&filter={"status":true}`],
    () => getFeeTypes(`filter={"status":true}`),
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  useEffect(() => {
    if (chartRef.current && feeTypeData) {
      const chart = new ApexCharts(chartRef.current, getChartOptions(feeTypeData));
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [feeTypeData, mode]);

  return (
    <div className="card p-4 w-100">
      <h5 className="fw-bold fs-7 mb-3">Fee Type Report</h5>
      <div ref={chartRef} id="fee_type_chart" />
    </div>
  );
};

export default FeeTypeStat;

function getChartOptions(feeTypeData?: FeeTypesQueryResponse): ApexOptions {
  const feeTypes = feeTypeData?.entities ?? [];

  const feeTypeCounts: Record<string, Record<string, number>> = {};

  feeTypes.forEach(({ title, created_at }) => {
    if (!title || !created_at) return; // Skip if title or created_at is missing

    let createdAtFormatted = "";

    if (created_at instanceof Date) {
      createdAtFormatted = created_at.toISOString().split("T")[0];
    } else if (typeof created_at === "string" && !isNaN(Date.parse(created_at))) {
      createdAtFormatted = new Date(created_at).toISOString().split("T")[0];
    }

    if (createdAtFormatted) {
      if (!feeTypeCounts[title]) {
        feeTypeCounts[title] = {};
      }
      feeTypeCounts[title][createdAtFormatted] = (feeTypeCounts[title][createdAtFormatted] || 0) + 1;
    }
  });

  // Ensure only valid dates are processed
  const allDates = Array.from(
    new Set(
      feeTypes
        .map((fee) => (fee.created_at ? new Date(fee.created_at).toISOString().split("T")[0] : null))
        .filter((date): date is string => date !== null) // Ensure date is a valid string
    )
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const series = Object.keys(feeTypeCounts).map((type) => ({
    name: type,
    data: allDates.map((date) => feeTypeCounts[type][date] || 0),
  }));

  return {
    series,
    chart: { type: "line", height: 250, fontFamily: "inherit" },
    xaxis: { categories: allDates, title: { text: "Creation Date" } },
    yaxis: { title: { text: "Count" } },
    stroke: { curve: "smooth", width: 3 },
    markers: { size: 5, hover: { size: 7 } },
    colors: ["#007bff", "#28a745", "#ff5733", "#f4c20d"],
    tooltip: { theme: "dark" },
  };
}
