import React from 'react'
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend} from 'recharts'
import {useQuery} from '@tanstack/react-query'
import {getStudentCountByClass} from '../core/_request'
import SchoolIcon from '@mui/icons-material/School'

type Props = {
  className: string
}

const COLORS = ['#1976D2', '#2196F3', '#64B5F6', '#90CAF9', '#BBDEFB']

const StudentStat: React.FC<Props> = ({className}) => {
  const {data: response} = useQuery([`get_student_count_by_class`], getStudentCountByClass, {
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const totalStudents = response?.reduce((sum, item) => sum + item.count, 0) || 0

  return (
    <div className={`card h-200px w-100 w-lg-400px ${className} d-flex flex-row p-4 gap-4`}>
      <div className='d-flex flex-column justify-content-between'>
        <h5 className=''>
          <span className='card-label fw-bold fs-7 mb'>Students</span>
        </h5>
        <SchoolIcon className='text-primary' sx={{fontSize: 78}} />
        <p>
          Total: <span>{totalStudents}</span>
        </p>
      </div>

      <div className='' style={{width: '180px', height: '180px'}}>
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart>
            <Pie
              data={response || []}
              dataKey='count'
              nameKey='name'
              cx='50%'
              cy='50%'
              outerRadius={80}
              fill='#1976D2'
              label={false}
            >
              {response?.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            {/* <Legend/> */}
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export {StudentStat}
